<template>
  <B-Modal
    id="modal-download-riwayat-stok"
    body-class="!p-6 text-black w-full"
    size="lg"
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-esc
  >
    <h3 class="text-[1.25rem] font-semibold text-black mb-[12px]">
      Download Riwayat Stock
    </h3>
    <p class="text-[1.125rem] mb-[1.5rem]">
      Kustomisasi riwayat stock yang ingin kamu download.
    </p>
    <div class="mb-[1.5rem]">
      <div class="grow relative text-[1.125rem] mb-[1rem]">
        <p class="mb-[10px]">
          Pilih rentang
        </p>
        <date-range-picker
          v-model="dateRange"
          :ranges="ranges"
          :locale-data="locale"
          class="w-full"
          control-container-class="border-[1px] border-[#E2E2E2] p-[12px] rounded-[8px] text-[1rem] text-[#626262] font-medium"
        >
          <template #input>
            <span>{{ formatDate }}</span>
            <span
              class="k-calendar inline-block text-primary text-[21px] float-right border-l-[1px] border-l-[#e2e2e2] pl-[8px]"
            />
          </template>
        </date-range-picker>
      </div>
      <multi-select
        id="pilih-produk-selection"
        v-model="selected"
        label="Pilih produk"
        placeholder="Pilih Produk"
        :options="produk"
      >
        <template #placeholder="slotProps">
          <b-spinner
            v-if="isLoading"
            variant="primary"
            label="Spinning"
            class="mr-1"
            small
          />
          <div class="grow m-0 text-[1rem] text-[#626262] font-medium">
            {{
              slotProps.selected.length > 0
                ? `${slotProps.selected.length} produk dipilih`
                : slotProps.initialText
            }}
          </div>
        </template>
      </multi-select>
    </div>
    <div class="flex align-between flex-col sm:flex-row gap-[1rem]">
      <div
        class="text-[0.75rem] font-medium grow"
      >
        <div
          v-if="percentageDownload > 0"
        >
          <p class="text-black mb-[6px]">
            sedang memuat file
          </p>
          <b-progress
            :value="percentageDownload"
            max="100"
            class="mb-[6px]"
          />
          <span>
            {{ loadingBarLabel }}
          </span>
        </div>
      </div>
      <div class="flex justify-end items-center gap-[12px] text-[1.125rem]">
        <b-button
          variant="outline-primary"
          @click="closeModal"
        >
          Batal
        </b-button>
        <b-button
          :variant="areFieldsComplete ? 'primary' : 'secondary'"
          :aria-disabled="!areFieldsComplete"
          :disabled="!areFieldsComplete"
          :class="{'w-[116px]': areDownloadInProgress}"
          @click="downloadRiwayatStok"
        >
          <b-spinner
            v-if="areDownloadInProgress"
            variant="light"
            label="Spinning"
            small
          />
          <span v-else>Download</span>
        </b-button>
      </div>
    </div>
  </B-Modal>
</template>

<script>
import { MultiSelect } from '@/views/components/select'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  last7, today, last30, last60, firstDateOfMonth,
} from '@/store/helpers'
import { toast_success, toast_error } from '@/libs/toastification'

export default {
  components: {
    MultiSelect,
    DateRangePicker,
  },
  props: {
    warehouseId: {
      type: Number,
      default: 0,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    produk: {
      type: Object,
      default: () => ({
        value: [],
        text: [],
      }),
    },
  },
  data() {
    return {
      selected: [],
      percentageDownload: 0,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      ranges: {
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        '60 Hari Terakhir': [last60, today],
        'Bulan Ini': [firstDateOfMonth, today],
        'Custom Tanggal': [null, null],
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Januari',
          'Februari',
          'Maret',
          'April',
          'Mei',
          'Juni',
          'Juli',
          'Agustus',
          'Septemper',
          'Oktober',
          'November',
          'Desember',
        ],
      },
    }
  },
  computed: {
    formatDate() {
      if (!this.dateRange.startDate) return 'Pilih rentang tanggal'
      const startDate = moment(this.dateRange.startDate).format('DD MMMM YYYY')
      const endDate = moment(this.dateRange.endDate).format('DD MMMM YYYY')
      return `${startDate} - ${endDate}`
    },
    areFieldsComplete() {
      return (
        !!this.dateRange.startDate && !!this.dateRange.endDate && !!this.selected && this.selected.length > 0
      )
    },
    areDownloadInProgress() {
      return this.percentageDownload > 0 && this.percentageDownload < 100
    },
    loadingBarLabel() {
      return (this.percentageDownload < 100) ? `${this.percentageDownload} %` : 'selesai'
    },
  },
  methods: {
    closeModal() {
      this.percentageDownload = 0
      this.$bvModal.hide('modal-download-riwayat-stok')
    },
    async downloadRiwayatStok() {
      this.percentageDownload = 0
      try {
        const selectedProduct = this.selected.length >= this.produk.value.length
          ? 'ALL'
          : this.selected.map(item => item).join(',')
        const response = await this.$http_new
          .get('/komship/api/v1/stock-opname/partner/download', {
            responseType: 'blob',
            params: {
              warehouse_id: this.warehouseId,
              start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
              end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
              product_ids: selectedProduct,
            },
            onDownloadProgress: progressEvent => {
              if (progressEvent.lengthComputable) {
                this.percentageDownload = Math.round((progressEvent.loaded / progressEvent.total) * 100)
              }
            },
          })

        const fileName = `${response.headers['x-filename']}.xlsx`
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        this.percentageDownload = 100
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)

        toast_success({
          title: 'Download Berhasil',
          message: 'File berhasil diunduh ke perangkat Anda.',
        })
      } catch (error) {
        this.percentageDownload = 0
        toast_error({
          title: 'Download Gagal',
          message: 'Terjadi kesalahan saat mengunduh file.',
        })
      }
    },
  },
}
</script>
